import React from "react";
import styles from "./CardProject.module.scss";

const CardProject = ({ project, onClick, hoveredCardId }) => {
  const isHovered = hoveredCardId === project.id;
  const cardClass = `${styles.cardContainer} ${
    isHovered ? styles.hoverEffect : ""
  }`;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div
          className={styles.bannerImage}
          style={{ backgroundImage: `url('${project.imageUrl}')` }}
          onClick={() => onClick(project)}
        ></div>
        <h1>{project.title}</h1>
        <p>{project.description}</p>
      </div>
      <div className={styles.buttonWrapper}>
        <button     onClick={() => onClick(project)} className={`${styles.btn} ${styles.btnOutline}`}>
          DETAILS
        </button>
      
      </div>
    </div>
  );
};

export default CardProject;
