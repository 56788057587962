import henka1 from "../../../image/henka1.png";
import henka0 from "../../../image/henka0.png";
import cabane0 from "../../../image/cabane0.png";
import cabane1 from "../../../image/cabane1.png";
import qalory0 from "../../../image/qalory0.png";
import qalory1 from "../../../image/qalory1.png";
import lune0 from "../../../image/lune0.png";
import lune1 from "../../../image/lune1.png";
import aqua0 from "../../../image/aqua0.png";
import aqua1 from "../../../image/aqua1.png";
import coiffure0 from "../../../image/coiffure0.png";
import coiffure1 from "../../../image/coiffure1.png";
import naturo0 from "../../../image/naturo0.png";
import naturo1 from "../../../image/naturo1.png";
import escapa0 from "../../../image/escapa0.png";
import escapa1 from "../../../image/escapa1.png";
import karat0 from "../../../image/karat0.png";
import karat1 from "../../../image/karat1.png";
import mamounettes0 from "../../../image/mamounettes0.png";
import mamounettes1 from "../../../image/mamounettes1.png";
import hearing0 from "../../../image/hearing0.png";
import hearing1 from "../../../image/hearing1.png";
import meli0 from "../../../image/meli0.png";
import meli1 from "../../../image/meli1.png";
import pranam0 from "../../../image/pranam0.png";
import pranam1 from "../../../image/pranam1.png";

export const dataProjects = [
  {
    id: 1,
    title: "HENKA COACHING",
    description:
      "Avec ce site, J'ai tissé un espace numérique propice à la transformation et à l'épanouissement",
    imageUrl: henka0,
    detailedDescription: "Description détaillée du projet Ocean Explorer...",
    additionalImages: [henka1],
    projectLink: "https://henka-coaching.fr",
    // Autres propriétés détaillées...
  },
  {
    id: 2,
    title: "La cabane mx",
    description:
      "Boostant chaque passionné de motocross vers une expérience de course supérieure ! Le site propulse la passion du tout-terrain au premier plan",
    imageUrl: cabane0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [cabane1],
    projectLink: "https://lacabanemx.fr",
    // Autres propriétés détaillées...
  },
  {
    id: 3,
    title: "Qalory",
    description:
      "Élever le digital tout en valorisant l'éco-responsabilité. Pour le projet de rénovation énergétique, nous avons fusionné technologie web et écologie",
    imageUrl: qalory0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [qalory1],
    projectLink: "https://qalory.com",
    // Autres propriétés détaillées...
  },
  {
    id: 4,
    title: "Lune Opticien",
    description:
      " Faisant converger l'art de l'optique avec la magie du digital 🤓🌐🔍.avons donné une nouvelle dimension à la vision en ligne de l'opticienne.",
    imageUrl: lune0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [lune1],
    projectLink: "https://opticienlune.fr",
    // Autres propriétés détaillées...
  },
  {
    id: 5,
    title: "AquaReef",
    description:
      "Plongez dans un équilibre parfait 🐠🌊 . L'application web est le compagnon indispensable de tous les passionnés d'aquariums récifaux.",
    imageUrl: aqua0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [aqua1],
    projectLink: "https://reef-gest.web.app/",
    // Autres propriétés détaillées...
  },
  {
    id: 6,
    title: "Instant Coiffure",
    description:
      "Révélant la beauté intrinsèque de chaque individu ✂️💇‍♀️. Le site est le reflet de notre dévouement à l'excellence en matière de coiffure.🐠🌊 . L'application web est le compagnon indispensable de tous les passionnés d'aquariums récifaux.",
    imageUrl: coiffure0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [coiffure1],
    projectLink: "",
    // Autres propriétés détaillées...
  },
  {
    id: 7,
    title: "Naturo-naturelle",
    description:
      " Pour concevoir quelque chose d'authentiquement unique 🌟🌟🌟, j'ai exploité le principal atout distinctif de la marque pour son site web. et de notre dévouement à l'excellence en matière de coiffure.🐠🌊 . L'application web est le compagnon indispensable de tous les passionnés d'aquariums récifaux.",
    imageUrl: naturo0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [naturo1],
    projectLink: "https://naturo-naturelle.fr",
    // Autres propriétés détaillées...
  },
  {
    id: 8,
    title: "Escapa2",
    description:
      " Pour façonner une aventure sur deux roues véritablement accessible 🚴‍♂️🌿✨, chaque détail a été pensé pour transformer chaque pédalage en une expérience libératrice.",

    imageUrl: escapa0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [escapa1],
    projectLink: "https://escapa2.fr",
    // Autres propriétés détaillées...
  },
  {
    id: 9,
    title: "Karat bijouterie",
    description:
      "Sertissant le monde digital avec des joyaux d'exception 💍✨🌐. Avec cette réalisation, nous avons sculpté une expérience digitale aussi précieuse que les gemmes présentées.",

    imageUrl: karat0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [karat1],
    projectLink: "https://karat-bijouterie.fr",
    // Autres propriétés détaillées...
  },
  {
    id: 10,
    title: "Les mamounettes",
    description:
      "Pour chaque sourire de bébé et chaque moment de tendresse entre une maman et son enfant 🍼💕🌼, le e-shop offre le produit parfait en parfaite harmonie avec leurs besoins.",

    imageUrl: mamounettes0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [mamounettes1],
    projectLink: "https://mamounettes.fr",
    // Autres propriétés détaillées...
  },
  {
    id: 11,
    title: "Hearing Space",
    description:
      " Amplifiant le futur de l'audition avec des solutions numériques de pointe 🔊🔧💡.Avec ce projet, nous avons défini la nouvelle norme pour les outils d'audioprothésistes",

    imageUrl: hearing0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [hearing1],
    projectLink: "https://hearing-space.com",
    // Autres propriétés détaillées...
  },
  {
    id: 12,
    title: "Méli ilèm",
    description:
      "Pour coudre un espace web vraiment unique ✂️🌐✨ j'ai filé chaque détail, transformant chaque clic en une découverte passionnante.",

    imageUrl: meli0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [meli1],
    projectLink: "https://meli-ilem.fr/",
    // Autres propriétés détaillées...
  },
  {
    id: 13,
    title: "pranam yoga",
    description:
      "Pour transmettre la puissance du yoga en toute authenticité 🧘‍♀️✨🌱,chaque pixel de ce site a été conçu pour refléter paix, harmonie et transformation.",

    imageUrl: pranam0,
    detailedDescription: "Description détaillée du projet Coral Reef Study...",
    additionalImages: [pranam1],
    projectLink: "https://pranâm-yoga.fr",
    // Autres propriétés détaillées...
  },
  // Autres projets...
];
