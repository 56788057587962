import React, { useState } from "react";
import TypeWriterText from "../../ui/TypeWriterText";
import { useNavigate } from "react-router-dom";
import GenerateBubbles from "../../animation/GenerateBubbles";
import styles from "./HeroSection.module.scss";

const HeroSection = () => {
  const [startAnimation, setStartAnimation] = useState(false);

  const title = "L'Odyssée de Guillaume Ribeau";
  const title2 = "En cours de construction...";
  const subTitle = "Plongez dans un univers de créativité et d'innovation";
  const navigate = useNavigate();

  const handleExploreClick = () => {
    setStartAnimation(true);
    setTimeout(() => navigate("/project"), 5000); // 5 seconds for animation to complete
  };

  return (
    <div className={styles.heroContainer}>
      <video autoPlay loop muted className={styles.videoBackground}>
        <source src="/fond-2.mp4" type="video/mp4" />
        Votre navigateur ne supporte pas les vidéos HTML5.
      </video>
    
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>
          <TypeWriterText text={title2} />
        </h1>
        {/* <p className={styles.subtitle}>{subTitle}</p> */}
        <button onClick={handleExploreClick} className={styles.exploreButton}>
          Explorez
        </button>
      </div>
      {startAnimation && <GenerateBubbles numBubbles={200} />}
    </div>
  );
};

export default HeroSection;
