import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.scss';

const Navbar = () => {
  return (
    <nav className={styles.navbar}>
      <ul className={styles.list}>
        <li>
          <Link to="/" className={styles.navLink}>Home</Link>
        </li>
        <li>
          <Link to="/project" className={styles.navLink}>Projet</Link>
        </li>
        {/* <li>
          <Link to="/contact" className={styles.navLink}>Contact</Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
