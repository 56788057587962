import React from "react";
import HeroSection from "./HeroSection";
import Navbar from "../../navbar/Navbar";
import FishGame from "../../game/FishGame";
import styles from './Home.module.scss'
import TypeWriterText from "../../ui/TypeWriterText";

const Home = () => {

  const title = "En cours de construction...";

  return (
    <>
      <Navbar />

      <HeroSection />
     
   
    </>
  );
};

export default Home;
