import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/pages/home/Home";
import Project from "./components/pages/project/Project";
import Contact from "./components/pages/contact/Contact";
import styles from "./styles/styles.scss";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/project" element={<Project />} />
        <Route path="/contact" element={<Contact />} />
        {/* Autres routes */}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
