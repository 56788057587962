import React, { useEffect } from 'react';
import Phaser from 'phaser';

const FishGame = () => {
  useEffect(() => {
    const config = {
      type: Phaser.AUTO,
      width: 800,
      height: 600,
      parent: "phaser-game",
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 200 }
        }
      },
      scene: {
        preload: preload,
        create: create,
        update: update
      }
    };

    new Phaser.Game(config);

    function preload() {
      this.load.setBaseURL('http://labs.phaser.io');
      this.load.image('sky', 'assets/skies/space3.png');
      this.load.image('fish', 'assets/sprites/red.png'); // Temporaire pour le poisson
    }

    function create() {
      this.add.image(400, 300, 'sky');

      const fishGroup = this.physics.add.group({
        key: 'fish',
        repeat: 10,
        setXY: { x: 12, y: 0, stepX: 70 }
      });

      fishGroup.children.iterate((fish) => {
        fish.setBounceY(Phaser.Math.FloatBetween(0.4, 0.8));
      });

      const paddle = this.physics.add.image(400, 550, 'fish').setScale(3).setImmovable();
      this.physics.add.collider(fishGroup, paddle, hitPaddle, null, this);

      this.input.keyboard.on('keydown-LEFT', () => { paddle.x -= 15; });
      this.input.keyboard.on('keydown-RIGHT', () => { paddle.x += 15; });

      this.score = 0;
      this.scoreText = this.add.text(16, 16, 'score: 0', { fontSize: '32px', fill: '#000' });
    }

    function hitPaddle(paddle, fish) {
      fish.disableBody(true, true);
      this.score += 1;
      this.scoreText.setText('Score: ' + this.score);
    }

    function update() {
      // Mettre à jour le jeu
    }

    return () => {
      // Nettoyer le jeu lors du démontage du composant
    };
  }, []);

  return <div id="phaser-game"></div>;
};

export default FishGame;
