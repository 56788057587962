import React, { useState, useEffect } from "react";
import CardProject from "./components/CardProject";
import { dataProjects } from "./dataProjects";
import ProjectModal from "./components/ProjectModal";
import Carousel from "./components/Carousel";
import Button from "../../ui/Button";
import styles from "./Project.module.scss";
import Navbar from "../../navbar/Navbar";
const Project = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [autoPlayActive, setAutoPlayActive] = useState(false);
  let autoPlayInterval = null;
  const projectIdsToPresent = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]; // Exemple d'IDs
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [modalAnimation, setModalAnimation] = useState("");

  const animations = [
    "fadeIn",
    "slideUp",
    "zoomIn",
    "slideInLeft",
    "slideInRight",
    "rotateIn",
    "bounceIn",
    "flipInX",
    "flipInY",
    "fadeInDown",
  ];

  const openModalWithRandomAnimation = (project) => {
    const randomAnimation =
      animations[Math.floor(Math.random() * animations.length)];
    setModalAnimation(randomAnimation);
    setSelectedProject(project);
  };

  useEffect(() => {
    return () => {
      if (autoPlayInterval) {
        clearInterval(autoPlayInterval);
      }
    };
  }, []);

  const handleCardClick = (project) => {
    console.log(project);
    setSelectedProject(project);
    // Ouvrir le modal ou afficher les détails
  };

  const startAutoPlay = () => {
    if (autoPlayActive) return;

    setAutoPlayActive(true);
    let projectIndex = 0;

    // Fonction pour ouvrir la modale pour un projet donné
    const openProjectModal = (projectId) => {
      const projectToPresent = dataProjects.find((p) => p.id === projectId);
      if (projectToPresent) {
        openModalWithRandomAnimation(projectToPresent); // Utilisez la fonction existante pour ouvrir la modale
        setHoveredCardId(projectId);
      }
    };

    // Ouvrir la modale pour le premier projet immédiatement
    if (projectIdsToPresent.length > 0) {
      openProjectModal(projectIdsToPresent[0]);
      projectIndex++;
    }

    // Configurer l'intervalle pour changer de projet toutes les 8 secondes
    autoPlayInterval = setInterval(() => {
      if (projectIndex < projectIdsToPresent.length) {
        openProjectModal(projectIdsToPresent[projectIndex]);
        projectIndex++;
      } else {
        // Arrêter la présentation automatique à la fin de la liste
        clearInterval(autoPlayInterval);
        setAutoPlayActive(false);
        setSelectedProject(null);
        setHoveredCardId(null);
      }
    }, 8000); // 8 secondes pour chaque projet
  };

  useEffect(() => {
    return () => {
      if (autoPlayInterval) {
        clearInterval(autoPlayInterval);
      }
    };
  }, []);

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={styles.containerProject}>
        <div className={styles.containerButton}>
          <Button
            text="Présentation automatique des projets"
            onClick={startAutoPlay}
          />
        </div>
        <div className={styles.projectGrid}>
          {dataProjects.map((project) => (
            <CardProject
              key={project.id}
              project={project}
              hoveredCardId={hoveredCardId}
              onClick={handleCardClick}
            />
          ))}
        </div>
        {selectedProject && (
          <ProjectModal
            isOpen={!!selectedProject}
            onRequestClose={() => {
              setSelectedProject(null);
              if (autoPlayActive) {
                clearInterval(autoPlayInterval);
                setAutoPlayActive(false);
              }
            }}
            project={selectedProject}
          />
        )}
      </div>
    </div>
  );
};

export default Project;
