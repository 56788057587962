import React from "react";
import styles from './GenerateBubble.module.scss'


const GenerateBubbles = ({ numBubbles }) => {
  const bubbles = Array.from({ length: numBubbles }, () => ({
    size: 10 + Math.random() * 20, // Taille entre 10px et 30px
    animationDuration: 3 + Math.random() * 7, // Durée entre 3 et 10 secondes
    left: Math.random() * 100, // Position X entre 0% et 100%
  }));

  return (
    <>
      {bubbles.map((bubble, index) => (
        <div
          key={index}
          className={styles.bubbleGenerate}
          style={{
            width: `${bubble.size}px`,
            height: `${bubble.size}px`,
            animationDuration: `${bubble.animationDuration}s`,
            left: `${bubble.left}%`,
            bottom: `${-bubble.size}px`, // Démarrer hors écran
          }}
        ></div>
      ))}
    </>
  );
};

export default GenerateBubbles;
