import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import styles from "./ProjectModal.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '70%', // Réduire la largeur (par exemple, à 60% de la largeur de la fenêtre)
      height: '70%', // Réduire la hauteur (par exemple, à 70% de la hauteur de la fenêtre)
      maxWidth: '1200px', // Vous pouvez également définir une largeur maximale
      maxHeight: '900px', // Et une hauteur maximale
      overflow: 'auto',
      zIndex: 100, // Assurez-vous que l'overlay est au-dessus des autres éléments de la page
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fond sombre semi-transparent pour l'overlay
      },
      // Permettre le défilement si le contenu est plus grand que la modale
      // Ajoutez ici d'autres styles de base nécessaires
    },
  };
  
  

const ProjectModal = ({ isOpen, onRequestClose, project }) => {
  const [animation, setAnimation] = useState("");
  const animations = ["fadeIn", "slideUp", "zoomIn", "slideInLeft", "slideInRight", "rotateIn", "bounceIn", "flipInX", "flipInY", "fadeInDown"];

  const modalClass = `${styles.modal} ${styles[animation]}`;

  useEffect(() => {
    if (isOpen) {
      const randomAnimation =
        animations[Math.floor(Math.random() * animations.length)];
      setAnimation(randomAnimation);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Project Details"
      ariaHideApp={false}
      style={customStyles}
      
      // Autres propriétés de style pour le modal
    >
         <div className={`${styles.modalContent} ${styles[animation]}`}>
      <h2>{project.title}</h2>
      <h3>{project.description}</h3>

      {project.additionalImages && project.additionalImages.length > 0 && (
        <Swiper
          modules={[Navigation, Autoplay]}
         navigation
          spaceBetween={50}
          slidesPerView={1}

        >
          {project.additionalImages.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                className="thumbnails-details-project"
                src={image}
                alt={`Slide ${index}`}
                style={{ width: "100%", height: "auto" }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
</div>
      <button onClick={onRequestClose}>Fermer</button>
    </Modal>
  );
};

export default ProjectModal;
