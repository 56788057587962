import React from 'react';
import Typewriter from 'react-typewriter-effect';

const TypeWriterText = ({ text }) => {
  return (
    <Typewriter
      text={text}
      cursor
      cursorStyle='_'
      typeSpeed={100}
      deleteSpeed={50}
      delaySpeed={1000}
    />
  );
};

export default TypeWriterText;
