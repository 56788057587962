
import styles from './Button.module.scss'

const Button = ({ onClick, text, className }) => {
   
    return (
      <div className={styles.oceanButton} onClick={onClick}>
        {text}
      </div>
    );
  };
  
  export default Button